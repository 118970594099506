<template>
  <main-document :tabs="tabs"> </main-document>
</template>

<script>

import tabController from '@/tabController'

export default {
  components: {
    MainDocument: () => import('@/components/main-document')
  },
  deactivated () {
    this.$removeFromKeepAliveCache()
    this.$destroy()
  },
  computed: {
    tabs () {
      return [tabController.create(tabController.accountContactsTab(), { documentId: this.$route.params.id, fromAccountId: this.fromAccountId })]
    }
  },
  props: {
    fromAccountId: String
  }
}
</script>
